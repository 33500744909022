<template>
  <div class="vm-section vm-articles">
    <v-container>
      <h3>Our News</h3>
      <div class="vm-articles-wrap">
        <v-row v-for="(item,i) in news" :key="i">
          <v-col class="col-12 col-md-4">
            <div class="n-sub-headings">
              <h4>
                <router-link :to="item.url">{{ item.title }}</router-link>
              </h4>
              <div class="n-date">{{ item.date_modified }}</div>
            </div>
          </v-col>
          <v-col class="col-12 col-md-5">
            <div class="n-intro">
              <div class="n-intro-content" v-html="item.introduction"></div>
              <router-link :to="item.url">> Read more</router-link>
            </div>
          </v-col>
          <v-col class="col-12 col-md-3">
            <div v-if="item.photos !== ''">
              <div
                class="n-intro-img"
                :style="{'background-image': `url(${item.photos})`}"
              />
            </div>
            <div
              v-else
              class="n-intro-img no-img"
              :style="{'background-image': `url(${require('@/assets/images/banner-default.jpg')})`}"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NewsArticles',
  data: () => ({
    news: [],
    loading: true,
    loaded: false,
    defaultImage: '\'/assets/images/banner-default.jpg\'',
  }),
  created () {
    let vue = this

    let cms_url = process.env.VUE_APP_CMS_HOST + '/latest-news.json'
    vue.axios.get(cms_url).then(response => {
      vue.news = response.data.data
    })
  },
}
</script>

<style lang="scss">
.vm-articles {
  .vm-articles-wrap {
    max-height: 700px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 3rem;
  }

  .row {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

    &:last-child {
      border-bottom: none;
    }
  }

  h3 {
    font-family: "Core Sans R 25", "Roboto", sans-serif !important;
    font-weight: inherit;
    font-size: 30px;
    margin-bottom: 3rem;
  }

  h4 {
    font-family: "Core Sans R 35", "Roboto", sans-serif !important;
    font-weight: inherit;
    font-size: 50px;
    line-height: 1;
    margin-bottom: 2rem;
  }

  .n-sub-headings {
    max-width: 700px;
    width: 100%;
    .n-date {
      font-size: 16px;
      color: #EE008C;
    }

    a {
      color: #000;
      text-decoration: none;

      &:hover {
        color: #EE008C;
      }
    }
  }

  .n-intro {
    font-size: 22px;
    width: 100%;
    .n-intro-content {
      margin-bottom: 2rem;
    }

    a {
      color: #EE008C;
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  .n-intro-img {
    width: 100%;
    height: 220px;
    background-color: #eee;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}

@media screen and (max-width: 960px) {
  .vm-articles h3 {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }
  .vm-articles .n-intro {
    font-size: 20px;
    max-width: initial;
    margin-left: 0;
  }
  .vm-articles .n-intro .n-intro-content {
    margin-bottom: 1rem;
  }
  .vm-articles h4 {
    font-size: 42px;
  }
}

@media screen and (max-width: 475px) {
  .vm-articles h4 {
    font-size: 36px;
  }
  .vm-articles .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .news-page {
    padding-top: 50px !important;
  }
  .news-page .vuma-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .vm-featured-news .next {
    left: 51%;
  }
  .vm-featured-news .prev {
    left: 45%;
  }
  .vm-featured-news .next, .vm-featured-news .prev {
    width: 15px;
    bottom: 0.5rem;
  }

  .vm-news-wrap .vm-social-media-feeds h3 {
    font-size: 26px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 325px) {
  .vm-articles h4 {
    font-size: 28px;
  }
}
</style>